<template>
  <div class="wrapper wrapper--login">
    <div class="center container">
      <loginForm />
    </div>
    <Footer />
  </div>
</template>

<script>
import loginForm from "@/components/adminLogin";
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";
export default {
  name: "login",
  components: { loginForm, Footer },
  computed: {
    ...mapGetters(["getNotification"]),
  },
};
</script>
