<template>
  <div class="login_content">
    <img src="@/assets/img/logo-big.png" alt="EarnForex" />

    <form @submit.prevent="recaptcha" name="loginForm" class="login_form">
      <Input name="email" label="E-mail" type="email" required />
      <Input name="password" label="Password" type="password" required />
      <label style="display: flex; cursor: pointer">
        <span class="input__label"> Remember me </span>
        <input type="checkbox" v-model="rememberMe" name="rememberMe" />
      </label>
      <button type="submit" class="btn btn--accent" :disabled="loading">
        <span v-if="!loading">Log in</span>
        <span v-else>...Loading</span>
      </button>
      <notificationTop
        :text="getNotification ? getNotification.text : null"
        :type="getNotification ? getNotification.type : null"
      />
    </form>
  </div>
</template>

<script>
import Input from "@/components/formFields/Input";
import notificationTop from "@/components/notification";
import { loginAdmin } from "@/components/graphQL/auth.gql";
import { checkRecaptcha } from "@/components/graphQL/userAuth.gql";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "loginForm",
  components: { Input, notificationTop },
  apollo: {
    loginAdmin: {
      query: loginAdmin,
      variables() {
        return {
          email: this.email,
          password: this.pass,
          rememberMe: this.rememberMe,
        };
      },
      skip() {
        return this.skipLogin;
      },
      result(result) {
        // console.log(result);
        if (result?.data?.loginAdmin?.success) {
          localStorage.setItem("Mode", "manager");
          localStorage.setItem("authToken", result.data.loginAdmin.token);
          localStorage.setItem("login", this.email);

          this.$router.push({ name: "brokers" });
        } else if (result?.data?.loginAdmin?.error) {
          this.skipLogin = true;
          this.setNotification({
            type: "error",
            text: "Sorry, wrong email or password. Try again",
            center: true,
          });
          this.loading = false;
        } else {
          this.skipLogin = true;
          this.setNotification({
            type: "error",
            text: "Sorry, something went wrong. Please, try again",
            center: true,
          });
          this.loading = false;
        }
      },
    },
    checkRecaptcha: {
      query: checkRecaptcha,
      variables() {
        return {
          token: this.recaptchaToken,
        };
      },
      skip() {
        return !this.recaptchaToken;
      },
      result(result) {
        this.link = result.data.checkRecaptcha
          ? ((this.skipLogin = false),
            (this.email = document.forms.loginForm.elements.email.value),
            (this.pass = document.forms.loginForm.elements.password.value))
          : null;
      },
    },
  },
  computed: {
    ...mapGetters(["getNotification"]),
  },
  data() {
    return {
      loading: false,
      skipLogin: true,
      email: "",
      pass: "",
      recaptchaToken: "",
      rememberMe: false,
    };
  },
  methods: {
    ...mapMutations(["setNotification", "setAdminEmail"]),

    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      this.loading = true;
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");

      this.recaptchaToken = token;
    },
    // Do stuff with the received token.
  },
  mounted() {},
};
</script>
